/* eslint-disable consistent-return */
// import AsyncStorage from '@react-native-community/async-storage';
import {getCustomStorage, removeStorageData, setCustomStorage, getSecureAuthToken} from '../storage';
export const PREF_AUTH_TOKEN = 'auth_token';
export const PREF_ROLE = 'role';
export const PREF_PLAYERID = 'player_Id';
export const PREF_EMAIL_ID = 'email_Id';
export const PREF_FIRST_NAME = 'first_name';
export const PREF_LAST_NAME = 'last_name';
export const ACTOR_DB_ACCESSIBLE = 'actor_db_access'; // to provide actor db access for sequence winner
export const FLOW_TYPE_TO_ACTOR_LIST = 'flow_to_actor_list'; // to decide the flow of category i.e, to check after choosing  archetype whether script to be displayed or choose location
export const FLOW_TYPE = 'flow_type'; // to identify role and change flow
export const SESSION_ID = 'sessionId'; // to identify role and change flow
export const CUST_ID = 'cust_id';
export const CUST_CAT_ID = 'cust_cat_id';
export const USER_ID = 'user_id';
export const USER_DETAIL = 'user_detail';
export const INSTID = 'INSTID';
export const RESCOUNTRYCODE = 'RESCOUNTRYCODE';
export const RESCOUNTRYID = 'RESCOUNTRYID';
export const COUNTRY_NAME = 'country_name';
export const LOGIN_ID = 'login_id';
export const CURRENCY_VALUE = 'currency_value';
export const DISBCURRENCY = 'DISBCURRENCY';
export const PREF_CURRENCY_CON = 'currencyConverter';
export const PURPOSE_POPUP_DETAIL = 'purpose_detail';
export const PURPOSE_POPUP_DETAILS = 'purpose_details';
export const PWORD = 'pword';
export const ACCOUNT_TYPE = 'account_type';
export const SIGNUP_OTP_VERIFIED = 'signup_otp_verified';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const TRANSFER_TYPE = 'transfer_type';
export const PRIMARY_DETAIL = 'primary_detail';
export const PURPOSEID = 'purpose_id';
export const BENEID = 'bene_id';
export const DELIVERYMODEID = 'delivery_mode';
export const BENEAMT = 'bene_amt';
export const CUSTTYPE = 'cust_type';
export const ACCID = 'acc_id';
export const PROMO_CODE = 'promo_code';
export const ACCNUMB = 'Account_No';
export const ACCNAME = 'Account_Name';
export const FROM_SCREEN = 'from_screen';
export const SIGNIN_OTP_VERIFIED = 'signin_otp_verified';
export const CONVERSION_MODE = 'conversion_mode';
export const PROMO_DETAIL = 'promo_detail';
export const CSRF_TOKEN = 'csrf_token';
export const YODLEE_INFO = 'yodlee_info';
export const ACHACN_ID = 'acn_ach_id';
export const REM_BANK_NAME = 'rem_bank_name';
export const ABA_ROUTING_NO = 'aba_routing';
export const ABA_ACC_TYPE = 'aba_account_type';
export const BENE_OWNER = 'beneficial_Owner';
export const M2IBENETYPEEQUI = 'beneficiary_recipient';
export const UTM_ARRAY = 'utm_code';
export const UTM_ARRAY_REG = 'utm_code_registration';
export const SOURCE_OF_FUND = 'Source_of_funds';
export const MLNG_BENEID = 'mlng_BeneId';
export const NOTIFICATION_COUNT = 'notification_count';
export const SECRET_KEY_UPDATION = 'secret_key_update';
export const OTP_STATUS = 'otp_status';
export const LN_SUCCESS_FILE_STATUS = 'ln_success_file_status';
export const VALIDATION_TRANSACTION_FLAG = 'validation_transaction_flag';
export const POWER_TRANS_PRODID = 'Power_transfer product id';
export const CHECK_MPIN = 'Mpin_status';
export const MPIN_EXIST = 'Mpin_status_Check';
export const MOBILE_MPIN = 'Mpin_mobile_number';
export const LN_STATUS = 'ln_status';
export const PASSWORD = 'password';
export const SSO_REG_DATA = 'sso_reg_data';
export const CUSTCATGCODE = 'customer_reward_info';
export const CCD_FLAG = 'cdd_flag';
export const NAVIGATE_FROM = 'Differentiate_page';
export const USER_LOCATION = 'user_current_location';
export const BILLER_NAME = 'Name_of_the_biller';
export const IS_IPHONE_X = 'iphone_x_or_greater';
export const TICKER_TEXT = 'ticker_text';
export const LOGIN_TICKER_TEXT = 'login_ticker_text';
export const CLOSE_TICKER_FLAG = 'close_ticker_flag';
export const CLOSE_TICKER_LOGIN_FLAG = 'close_ticker_login_flag';
export const YODLEESUPFLAG = 'yodlee_supported_flag';
export const YODNICKNAME = 'yodlee_nickname';
export const MENULOGINPRESSED = 'menu_login_press';
export const AMOUNT_WITHOUT_PROMO = 'sender_amount_without_promo';
export const ACCOUNT_REDIRECTION_FLAG = 'account_redirection_flag';
export const CLOSE_WEB_VIEW = 'close_web view';
export const ACH_REVERIFY = 'ach_verify';
export const CUST_RISK_RATE_FLAG = 'Customer_risk_rate_flag';
export const TRANSACTION_DETAILS = 'Transaction_details';
export const M2IRIBID = 'Recipient_request_id';
export const ONLY_PROMO = 'only_promo';
export const FIXED_INDICATIVE = 'fixed or indicative';
export const TOUCH_ID_FLAG = 'Touch_id_flag';
export const PREF_MPIN_CHECK = 'pref mpin check';
export const ROUTER = 'router';
export const IS_FOOTER_VISIBLE = 'is_footer_visible';
export const GSTNUMBER = 'Gst number';
export const MARKET_FLAGS = 'market communication flags';
export const FIREFOX_INFO = 'firefox info';
export const DIFF_RATE = 'diffrate';
export const COMMWITHOUTPROMO = 'commision without promo';
export const LANDING_BEAN_SECOND_STEP = 'landing bean second step';
export const LOADCOUNT = 'page load count';
export const CURRENT_PAGE = 'current page name';
export const PREVEOUSURL = 'current page';
export const IDENTIFIER = 'identifier';
export const COUPON_PAISE = 'coupon paise';
export const PROMO_CAP_FLAG = 'promo cap flag';
export const MAX_FC_AMT_CAP = 'max fc amount cap';
export const OLD_EXCHANGE_RATE = 'old exchange rate';
export const PROMO_APPLIED = 'promo applied';
export const FINAL_EXCHANGE_RATE = 'final exchange rate';
export const MEMBERSHIP_APPLIED = 'membership applied';
export const PROMO_BENEFIT = 'promo benefit';
export const UK_CONSENT_FLAG = 'uk consent flag';
export const LN_DOC_INFO = 'ln doc info flag';
export const EQUIFAX_STATUS = 'equifax status';
export const SSN_STATUS = 'ssn verification status';
export const STATE_Y_FLAG_FOR_RECIPIENT = 'state flag for recipient';
export const BENEF_ID = 'Benef id for popup';
export const BENEF_TYPE = 'Benef type for popup';
export const VIEW_RECP_DETAILS = 'View Recipient details';


export const storePrefData = async (key, value) => {
  try {
    setCustomStorage(key, value);
  } catch (e) {}
};

// eslint-disable-next-line consistent-return
export const getPrefData = async (key, defaultValue) => {
  try {
    const val = await getCustomStorage(key);

    return val;
  } catch (e) {
    return defaultValue;
  }
};

export const removePrefData = async (key, defaultValue) => {
  try {
    await removeStorageData(key);
  } catch (e) {
    return defaultValue;
  }
};

export const getAuthToken = async () => {
  try {
    const val = await getSecureAuthToken();
    return val;
  } catch (e) {
    // error reading value
  }
};